.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  padding: 0;
  text-indent: 0;
  & > div {
    padding: var(--size-1) 0;
  }
  &.table-grey {
    color: var(--color-dark);
    & span:nth-child(2) {
      text-align: right;
    }
    & > div {
      border-bottom: 1px solid var(--color-blue-light-1);
      display: flex;
      justify-content: space-between;
    }

    & > div:last-of-type {
      border-bottom: none;
    }
  }
}
