.box {
  padding: var(--gutter-16);

  &.box-small {
    padding: var(--gutter-8);
  }

  &.box-large {
    padding: var(--gutter-24);
  }

  &.box-vertical {
    padding-left: 0;
    padding-right: 0;
  }

  &.box-horizontal {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.box-sqished-1 {
    padding: var(--gutter-24) var(--gutter-16);
  }

  &.box-no-top {
    padding-top: 0;
  }
  &.box-no-sides {
    padding-left: 0;
    padding-right: 0;
  }

  &.box-no-right {
    padding-right: 0;
  }

  &.box-no-right-lg {
    @media (--desktop) {
      padding-right: 0;
    }
  }

  &.box-no-left-lg {
    @media (--desktop) {
      padding-left: 0;
    }
  }

  &.box-mobile {
    @media (--mobile) {
      padding: var(--gutter-20);
    }
  }
}
