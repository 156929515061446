.forgot-password-form-wrapper {
  background-color: var(--color-blue-light-2);
}

.reset-password-confirmation-wrapper {
  background-color: var(--color-blue-light-2);
  padding: var(--size-57) 15% 0 15%;
  height: 100%;
  color: var(--color-dark-blue);
  @media (--mobile-up) {
    padding: var(--size-57) 33% 0 33%;
  }
}
