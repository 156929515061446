.rdw-option-wrapper {
  border: 1px solid transparent;

  &:hover {
    box-shadow: none;
    border: 1px solid var(--color-dark-blue-grey-2);
  }

  &.rdw-option-active {
    box-shadow: none;
    border: 1px solid var(--color-blue-1);
  }
}

.rdw-editor-toolbar {
  border: none;
}

.rdw-editor-wrapper {
  border: 1px solid var(--color-dark-blue-grey-2);
  background-color: var(--color-white);
  margin-top: var(--size-0-25);

  &:focus-within {
    border: 1px solid var(--color-blue-selected);
  }

  &:hover {
    border: 1px solid var(--color-blue-selected);
  }

  &.disabled {
    background-color: var(--color-seledin-light-1);
  }
}

.rdw-editor-main {
  padding: 0 14px 0;
  min-height: var(--size-128);
}

.rdw-editor {
    line-height: 24px;
}

.public-DraftStyleDefault-block {
    margin: 0;
    padding: 0;
    line-height: 24px;
}
