#login-view {
  background-color: var(--color-blue-light-2);
  & .login-wrapper {
    padding-inline: var(--gutter-16);
    max-width: 400px;
    & .header {
      margin-bottom: var(--size-81);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: var(--size-3);
      &.logo {
        font-family: 'Gotham Bold';
        font-weight: bold;
        color: var(--color-dark);
      }
    }
    & form {
      & .nav-link {
        color: var(--color-dark-blue-grey-1);
        margin-inline: auto;
        margin-top: var(--gutter-24);
      }
    }
  }
}
