h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}

h1,
.h1 {
  font-size: var(--font-size-24);
  font-family: var(--fonts-family-bold);
}

h2,
.h2 {
  font-size: var(--font-size-20);
  font-family: var(--fonts-family-bold);
}

h3,
.h3 {
  font-family: var(--fonts-family-medium);
  font-size: var(--font-size-17);
}

.h4 {
  font-size: var(--font-size-16);
}

/* Heading sizes used for offer page */
.h1--web {
  font-size: var(--size-28);
  font-family: var(--fonts-family-bold);

  @media (--desktop) {
    font-size: var(--size-32);
  }
}

.h2--web {
  font-size: var(--size-24);
  font-family: var(--fonts-family-bold);
}

.h3--web {
  font-size: var(--size-20);
  font-family: var(--fonts-family-medium);
}
