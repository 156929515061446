.radio-wrapper {
  & label {
    max-width: 88%;
    margin-bottom: 0;
  }

  & .input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: var(--size-2);
    width: var(--size-2);
    height: var(--size-2);

    &.big {
      width: var(--size-2);
      height: var(--size-2);
    }

    & input {
      position: absolute;
      inset: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      z-index: 1;

      &:checked + span:before {
        transform: scale(0.55);
      }
      &:disabled:checked + span {
        filter: contrast(0.9);
      }
      &:disabled:checked + span::before {
        background-color: var(--color-dark-blue);
      }
      &:disabled:not(:checked) + span {
        background-color: var(--color-seledin-light-1);
      }
    }
    & span {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: var(--color-white);
      box-shadow: inset 0 0 0 1px var(--color-dark-2);
      will-change: box-shadow;
      transition: box-shadow 150ms linear;
      display: flex;
      justify-content: center;
      align-items: center;
      &:before {
        content: '';
        position: absolute;
        inset: 0;
        background-color: var(--color-dark);
        border-radius: 50%;
        transform: scale(0);
        transition: transform 200ms linear;
      }
    }

    &:hover {
      & span {
        box-shadow: inset 0 0 0 1px var(--color-blue-selected);
      }
    }
  }
}
