.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  &.no-wrap {
    flex-wrap: nowrap;
  }
  &.scrollable-x {
    overflow-x: scroll;
  }
}

.reversed-direction {
  flex-direction: row-reverse;
}

.space-between {
  justify-content: space-between;
}

.to-the-end {
  justify-content: flex-end;
}
.to-the-start {
  justify-content: flex-start;
}

.center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.align-baseline {
  align-items: baseline;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.relative {
  position: relative;
}

.no-shrink {
  flex-shrink: 0;
}
