.form-wrapper {
  & h3 {
    margin-top: var(--size-12);
    margin-bottom: var(--size-12);
  }

  & h3 {
    margin-top: var(--size-12);
    margin-bottom: var(--size-12);
  }
}

.form-row {
  display: grid;
  grid-column-gap: var(--gutter-16);
  row-gap: var(--gutter-16);
  grid-template-columns: 1fr 1fr;

  @media (--mobile) {
    grid-template-columns: 1fr;
  }
}

.contents {
  display: contents;
}

.projection-form__title {
  padding: var(--gutter-16) 0;
}
