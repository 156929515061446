.overlay {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: var(--color-dark);
  opacity: 0;
  z-index: 2;
    transition: height 0ms 300ms,
    opacity 300ms 0ms;
  &.active {
    height: 100%;
    opacity: 0.5; /* To match MUI drawer until we've converted */
      transition: height 0ms 0ms,
      opacity 300ms 0ms;
  }
}

.edit-column.active {
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
    0px 16px 24px 2px rgba(0, 0, 0, 0.14),
    0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
