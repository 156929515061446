.rich-text-preview {
    &.web-content {
        & p {
            font-size: 13px;
            line-height: 20px;
        }

        & strong {
            font-size: 16px;
            line-height: 24px;
            font-family: var(--fonts-family-medium);
        }
    }

    & p {
        line-height: 20px
    }
  & strong {
      font-family: var(--fonts-family-medium);
  }
  & p:empty:before {
    content: ' ';
    white-space: pre;
  }
}
