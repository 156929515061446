input,
select {
  height: var(--size-48);
  width: 100%;
  font-size: 1rem;
  margin-top: var(--size-0-25);
  transition: border 0.2s ease-in;

  &.input-main {
    border-radius: 0;
    padding-left: var(--size-1);
    padding-right: var(--size-1);
    border: var(--border-grey-single);
    background-color: var(--color-white);
    &::placeholder {
      color: var(--color-dark-blue-grey-1);
      font-size: 1rem;
    }
  }
  &[type='radio'] {
    width: auto;
    height: auto;
  }
  &[type='text'].error {
    border-color: var(--color-red);
  }
  &:disabled {
    color: var(--color-dark-blue-grey-2);
    background-color: var(--color-seledin-light-1);
    border: var(--border-grey-dark);

    &:hover {
      border: var(--border-grey-dark);
    }
  }

  &:focus {
    outline: none;
    border: 1px solid var(--color-blue-selected);
  }

  &:hover {
    border: 1px solid var(--color-blue-selected);
  }
}

textarea {
  width: 100%;
  font-size: 1rem;
  transition: border 0.2s ease-in;
  max-width: 100%;
  min-width: 100%;
  min-height: 5rem;
  margin-top: var(--size-0-25);

  &.input-main {
    border: none;
    border-radius: 0;
    padding: var(--size-1);
    border: var(--border-grey-dark);
    color: var(--color-dark-blue-grey-1);
    background-color: var(--color-white);
    outline: none;
    &::placeholder {
      color: var(--color-dark-blue-grey-1);
      font-size: 1rem;
    }
  }
  &.basic-input {
    color: var(--color-gray-3);
  }
  &.static-size {
    resize: none;
  }
  &:focus {
    outline: none;
    border: 1px solid var(--color-blue-selected);
  }
}
label {
  color: var(--color-dark-blue);
  padding-right: var(--size-1);
  &.dark {
    color: var(--color-dark-blue);
  }
  &.disabled {
    color: var(--color-dark-blue-grey-1);
  }
}
