.geographic-direction {
  margin: 0 0 var(--gutter-8);
}

.roof-wrapper {
  display: flex;

  & .roof-icon-wrapper {
    flex-basis: var(--gutter-36);
  }

  & header {
    margin-bottom: var(--gutter-24);
  }
}

.number-of-panels-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: var(--gutter-8);

  & img {
    display: inline-block;
    margin-right: var(--gutter-8);
    width: var(--size-18);
  }
}
