#right-panel-sub-for-creating-user {
  background-color: var(--color-blue-light-2);
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
  transition: right 250ms linear;
  &.visible {
    right: 0;
  }
  &.hidden {
    right: -100%;
  }
  & .header {
    & .icon {
      margin-left: var(--size-16);
      margin-right: var(--size-32);
    }
  }
}

.column > #right-panel-sub-for-creating-user {
  position: absolute;
}
