.icon {
  cursor: pointer;

  &.disabled {
    cursor: default !important;
    color: var(--color-dark-blue-grey-2) !important;
    pointer-events: none;
  }

  &.icon-small {
    width: var(--icon-small);
    height: auto;
    max-height: var(--icon-small);
  }
  &.icon-small-2 {
    width: var(--icon-small-2);
    height: auto;
    max-height: var(--icon-small-2);
  }
  &.icon-medium {
    width: var(--icon-medium);
    height: auto;
    max-height: var(--icon-medium);
  }
  &.icon-large {
    width: var(--icon-large);
    height: auto;
    max-height: var(--icon-large);
  }
  &.color-blue {
    color: var(--color-blue-1);
  }
  &.color-grey {
    color: var(--color-dark-blue-grey-2);
  }
  &.color-green {
    color: var(--color-green-1);
  }
  &.color-dark {
    color: var(--color-dark);
  }
  &.color-white {
    color: var(--color-white);
  }
}

.delete-wrapper {
  max-width: 200px;
  margin-left: auto;
}
