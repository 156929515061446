/* Offer top + dropdown */
.offer-topbar {
  position: sticky;
  background: var(--color-white);
  top: 0;
  z-index: 9;

  @media (--mobile-and-tablet) {
    padding-left: var(--gutter-20);
    padding-right: var(--gutter-20);
  }
}

.offer-management-popup__wrapper {
  margin-left: auto;
  flex-shrink: 0;
}

.offer-management-popup__button {
  background: transparent;
  transition: all ease-in-out 0.2s;
  padding: 0 var(--gutter-16);
  height: var(--size-48);
  cursor: pointer;

  & h4 {
    color: var(--color-dark-blue);
    transition: color 0.2s ease-in;
  }

  & .icon {
    transition: transform 0.2s ease-in,
    color 0.2s ease-in;
  }

  &.bg-gray {
    background-color: var(--color-blue-light-2);
  }

  &:hover {
    & h4 {
      color: var(--color-blue-1);
    }
  }
}

.offer-management-popup {
  z-index: 1;
  width: calc(100vw - (var(--gutter-20) * 2));

  @media (--tablet-up) {
    width: 100%;

    &.size--440 {
      max-width: 440px;
    }

    &.size--395 {
      max-width: 395px;
    }
  }

  & .offer-management-popup__content {
    padding: var(--gutter-20);
    background-color: var(--color-white);
    border: 1px solid rgba(0, 0, 0, 0.16);
    gap: var(--gutter-20);

    @media (--tablet-up) {
      padding: var(--size-36) var(--gutter-24) var(--gutter-24) var(--gutter-24);
      gap: var(--gutter-24);
    }
  }
}

.dropdown-open {
  & .offer-management-popup__button {
    background: var(--color-blue-selected);

    & h4 {
      color: var(--color-white);
    }

    & .icon {
      color: var(--color-white);
      transform: rotate(180deg);
    }
  }
}

/* Offer content */

.sub-section {
  padding-top: var(--size-20);

  @media (--desktop) {
    padding-top: var(--size-40);
  }
}

.sticky-panel {
  padding-top: var(--gutter-24);
}

.offer-partner-information {
  & .logo {
    align-self: stretch;
    & img {
      max-width: 144px;
      max-height: 108px;
    }
  }
  & .offer-date,
  & .expires-on {
    font-size: var(--font-size-13);
    color: var(--color-dark-blue);
  }
  & .map {
    width: 100%;
    height: 284px;
    aspect-ratio: 16/9;
  }
}

.offer-column {
  & > .card:last-child {
    margin-bottom: var(--gutter-16);
  }
  & .contact-wrapper {
    & a {
      color: var(--color-blue-1);
    }
  }
}

/*to remove*/
.offer-page .page-view-wrapper {
  background-color: red !important;
}

.information-block-content {
  position: relative;
  & a {
      margin-top: 0.5rem;
    & p {
      line-height: 100%;
      text-align: center;
      margin: auto 0;
    }
  }

  & .teaser-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & .teaser-content {
    z-index: 1;
  }
}

.offer-agreement-subscription-wrapper {
  border: 1px solid var(--color-dark-blue-grey-2);
  padding: var(--gutter-24);
  box-sizing: border-box;
  background-color: var(--color-blue-light-2);
}

.terms-and-conditions-link {
  color: var(--color-blue-1);
  &:hover {
    cursor: pointer;
  }
}

.offer-management-popup {
  z-index: 1;
}

.teasers-horizontal-line {
    z-index: 1;
}

/* Offer page for dealers */
.offer-page--no-partner-assigned {
  & .charts-container {
    grid-template-columns: 1fr 1fr;

    & .chart-card.full-row {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
}
