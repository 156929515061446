.column {
  display: flex;
  flex-direction: column;
  &.center {
    align-items: center;
    justify-content: center;
  }
  &.center-verticaly {
    justify-content: center;
  }
  &.center-horizontaly {
    align-items: center;
  }
  &.align-top {
    justify-content: flex-start;
  }
  &.align-bottom {
    justify-content: flex-end;
  }
  &.w-30-p {
    width: 30%;
  }
  &.w-70-p {
    width: 70%;
  }
  @media (--mobile-small) {
    &.col-1-mobile-small {
      width: 100%;
    }
    &.fw-mobile-small {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 100%;
    }
  }
  @media (--mobile-small-up) {
    &.mobile-up-30 {
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: 30%;
    }
    &.mobile-up-fill {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
    }
  }
  @media (--desktop) {
    &.col-4-desktop {
      width: 25%;
    }
  }

  &.no-overflow {
    overflow-y: hidden;
  }
  &.no-wrap {
    flex-wrap: nowrap;
  }
  &.scrollable-x {
    overflow-x: scroll;
  }
}

.gap {
  gap: var(--gutter-24);
}
.gap-big {
  gap: var(--gutter-margin-y);
}
.gap-small {
  gap: var(--gutter-16);
}
.gap-extra-small {
  gap: var(--gutter-8);
}

.gap-4 {
  gap: var(--gutter-4);
}

.gap-36 {
  gap: var(--gutter-36);
}

.gap-extra-large {
  gap: var(--size-20);

  @media (--desktop) {
    gap: var(--size-60);
  }
}
.no-gap {
  gap: 0;
}
