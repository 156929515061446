.react-select {
  color: var(--color-dark-blue-grey-2);
  margin-top: var(--size-0-25);

  & .react-select__control {
    border: var(--border-grey-dark);
    border-radius: 0;
    box-shadow: none;

    &:focus {
      outline: none;
    }

    &:focus-within {
      border: 1px solid var(--color-blue-selected);
    }

    &:not(.react-select__control--is-disabled):hover {
      border-color: var(--color-blue-selected);
      cursor: pointer;
    }

    & .react-select__placeholder {
      color: var(--color-dark-blue-grey-2);
    }
    & .react-select__value-container {
      padding-inline: var(--size-1);
      padding-block: 0;
    }
    & .react-select__input-container,
    & .react-select__single-value {
      padding: 0;
      margin: 0;
    }
  }
  & .react-select__indicator-separator {
    display: none;
  }
  & .react-select__indicator {
    color: var(--color-dark-blue-grey-1);
  }
  & .react-select__menu {
    border-radius: 0;
    border: solid 1px var(--color-dark-2);
    margin: 0;
    padding: var(--size-1);
  }
  & .react-select__menu-list {
    padding: 0;
  }
  & .react-select__option {
    cursor: pointer;
    background-color: var(--color-white);
    color: var(--color-dark-blue);
    padding: var(--size-0-5) 0;
  }
  & .react-select__option--is-selected {
    color: var(--color-blue-1);
  }
  &.error {
    & .react-select__control {
      border-color: var(--color-red);
    }
  }
  &.react-select--is-disabled {
    color: var(--color-dark-blue-grey-1);
    background-color: var(--color-seledin-light-1);
    pointer-events: auto;
  }

  &:focus {
    outline: none;
    border: 1px solid var(--color-blue-1);
  }

  & .css-select-control:hover {
    border: 1px solid var(--color-blue-1);
    box-shadow: none;
    cursor: pointer;
  }
  &.first-row-separated {
    & .react-select__menu {
      & .react-select__option:first-child {
        border-bottom: var(--border-grey-dark);
        padding: var(--size-1) 0;
      }
      & .react-select__option:nth-of-type(2) {
        padding-top: var(--size-1);
      }
    }
  }
}
