.navbar,
.drawer-content {
  .nav-item,
  .go-back-button + .nav-options .nav-item.active {
    font-family: var(--fonts-family-medium);
    font-size: 1.125rem;
  }

  .nav-options {
    .nav-link {
      color: var(--font-dark);
      text-decoration: none;
      display: flex;
      align-items: center;

      .option-icon {
        display: none;
      }
    }

    &.nav-options-base .nav-item,
    .nav-item.active {
      font-family: var(--fonts-family-bold);
      font-size: 1.25rem;
    }
  }
}

.drawer-content .nav-item .nav-link {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .option-icon {
    display: block;
  }

  &:hover {
    background-color: var(--color-blue-light-3);
  }
}

@media (--desktop) {
  .drawer-content {
    width: calc((((100vw - (11 * var(--gutter-24))) / 12) * 3) + (2 * var(--gutter-24)));
  }
}

@media (--desktop-small) {
  .drawer-content {
    width: calc((((100vw - (11 * var(--gutter-24))) / 12) * 4) + (3 * var(--gutter-24)));
  }
}
