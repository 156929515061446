.slide-in-view-wrapper {
  position: fixed;
  inset: 0;
  transform: translateY(100%);
  background-color: var(--color-white);
  transition: transform 0.3s ease-out;
    z-index: 20;

  &.visible {
    transform: translateY(0);
  }

  & .slide-in-view-header {
    height: var(--size-80);
    align-items: center;
  }

  & .slide-in-view-content-wrapper {
    flex: 1 1 auto;
    overflow-y: scroll;
    overflow-x: hidden;
    overscroll-behavior: contain;

    .edit-submit-buttons-wrapper {
      display: flex;
      place-self: end;
      min-width: 300px;
    }

    @media (--mobile-and-tablet) {
      padding-top: var(--gutter-24);

      .edit-submit-buttons-wrapper {
        place-self: center;
        justify-content: center;
        width: 100%;
      }
    }
  }
}
