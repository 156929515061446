/*Only book and medium are used*/
/*rest is here for future convinient*/
/* Light */
/* @font-face {
  font-family: "Gotham";
  src: url("./GothamLight.woff") format("woff"),
    url("./GothamLight.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
} */

/* Medium */
@font-face {
  font-family: 'Gotham Medium';
  src: url('./GothamMedium.woff') format('woff'),
  url('./GothamMedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

/* Book */
@font-face {
  font-family: 'Gotham Book';
  src: url('./GothamBook.woff') format('woff'),
  url('./GothamBook.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

/* Bold */
@font-face {
  font-family: 'Gotham Bold';
  src: url('./GothamBold.woff') format('woff'),
  url('./GothamBold.ttf') format('truetype');
  font-weight: 700;
  font-style: bold;
  font-display: fallback;
}

/* Black */
/* @font-face {
  font-family: "Gotham";
  src: url("./GothamBlack.woff") format("woff"),
    url("./GothamBlack.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: fallback;
} */
