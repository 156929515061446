.grid {
  display: grid;
  &.two-col {
    grid-template-columns: 1fr 1fr;
    @media (--mobile-and-small) {
      &.one-col-mobile {
        grid-template-columns: 1fr;
      }
    }
  }
  &.three-col {
    grid-template-columns: 3fr 2fr 2fr;
    @media (--mobile-and-small) {
      &.one-col-mobile {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}
