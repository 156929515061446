.bold,
b {
  font-family: var(--fonts-family-bold);
}

.medium {
  font-family: var(--fonts-family-medium);
}

.normal {
  font-family: var(--fonts-family-normal);
}

.i {
  color: var(--color-blue-1);
}

.disabled {
  color: var(--color-dark-blue-grey-2);
}

.small {
  font-size: var(--font-size-13);
  line-height: var(--size-16);
}

.link {
  color: var(--color-blue-1);
  text-decoration: none;
  text-transform: uppercase;
  font-family: var(--fonts-family-bold);
}

.text-link {
  color: var(--color-blue-1);
  text-decoration: none;
  transition: color 150ms linear;

  &:hover {
    color: var(--hover-color);
  }
}

.text-link--icon {
  display: flex;
  align-items: center;
  cursor: pointer;

  & .icon {
    margin-right: var(--size-8);
  }

  & p {
    transition: color 0.2s ease-in;
  }

  &:hover > p {
    color: var(--color-blue-1);
  }

  &:hover > p.disabled {
    color: var(--color-dark-blue-grey-2);
  }
}

.display-linebreak {
  white-space: pre-line;
}

p,
.p,
li {
  font-size: var(--size-16);
  line-height: var(--size-22);
  margin: 0;
  padding: 0;
  & .small {
    font-size: var(--font-size-13);
  }
}

.color-grey-1 {
  color: var(--color-dark-blue-grey-1);
}

.color-blue-1 {
  color: var(--color-blue-1) !important;
}

.color-seledin {
  color: var(--color-seledin);
}

.no-decoration {
  text-decoration: none;
}

ul {
  padding-left: var(--size-20);
  margin: var(--size-8) 0;
}

.center-vertically {
  margin-top: auto;
  margin-bottom: auto;
}

.pointer {
  cursor: pointer;
}
