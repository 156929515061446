@page {
  size: A4 portrait;
  margin: 2cm 3cm;
}

@media print {
  .no-print {
    display: none !important;
  }

  .page-break {
    page-break-before: always;
  }

  .no-break {
    page-break-inside: avoid;
  }

  .information-block-content.box.box-large {
    padding-left: 0;
    padding-right: 0;
  }

  .chart-card .chart-container > div canvas {
    width: 100% !important;
  }
}
