.numeric-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 58px;
  background-color: var(--color-blue-4);
  padding: 5px;
  height: var(--size-48);
  &.no-background {
    background-color: transparent;
  }

  & .numeric-input {
    border: none;
    background-color: transparent;
    color: var(--color-dark-blue);
    font-family: var(--fonts-family-normal);
    text-align: center;
    height: 100%;
      width: 100% !important;
    margin-top: 0;

    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  & .numeric-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1 / 1;
    height: calc(48px - 10px);
    border-radius: 50%;
    border: var(--border-blue-2);
    background-color: var(--color-white);
    color: var(--color-blue-1);
    cursor: pointer;
    transition: border-color 0.15s ease-in;

    &:last-of-type {
      order: 3;
    }

    &:disabled {
      color: var(--color-dark-blue-grey-2);
      border: var(--border-grey-dark);
      cursor: default;

      &:hover {
        border-color: var(--border-grey-dark);
        & svg {
          color: var(--color-dark-blue-grey-2);
        }
      }
    }

    & svg {
      position: absolute;
      top: 20%;
      left: 20%;
      height: 60%;
      width: 60%;
      transition: color 0.15s ease-in;
    }

    &:hover,
    &:focus-visible {
      border-color: var(--hover-color);
      outline: none;
      & svg {
        color: var(--hover-color);
      }
    }
  }

  &.small {
    height: var(--size-38);
    padding: 0 3px;
    width: 133px;

    & .numeric-button {
      height: var(--size-32);
    }
  }
}
