.page-view-wrapper {
  position: relative;
  flex: 1;
  background-color: var(--color-white);
  & #page-view-port {
    min-height: 0;
    transition: transform 300ms linear;
    @media (--desktop) {
      gap: var(--gutter-24);
    }
  }
  & .page-view {
    position: relative;
    width: 100%;
    background-color: var(--color-blue-light-2);
    @media (--mobile-and-tablet) {
      overflow: hidden;
    }
    & .spacer {
      margin-inline: var(--size-0-3-em);
    }
    & .page-list-wrapper {
      position: relative;
      background-color: var(--color-white);
    }
    & #page-view-create-item {
      @media (--desktop) {
        border: var(--border-grey-single);
        display: none;
        animation: fadeInFromNone 150ms linear both;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 1rem;
          height: 1rem;
          border-width: 1px;
          border-color: var(--color-dark-2) transparent transparent var(--color-dark-2);
          border-style: solid;
          background-color: var(--color-white);
          transform: rotate(315deg) translateX(-100%) translateY(29%);
        }

        &.active {
          display: block;
          & ~ #page-view-selected-item-info,
          & ~ #page-view-selected-item-edit {
            display: none;
          }
        }
      }
    }
    & #page-view-selected-item-info {
      & .item-info-page-top-header {
        font-family: var(--fonts-family-bold);
        font-size: var(--font-size-20);

        @media (--desktop) {
          font-size: var(--font-size-5);
          line-height: calc(var(--font-size-5) + 1rem);
        }
      }
      @media (--desktop) {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: 40%;
        opacity: 0;
        visibility: hidden;
        z-index: 0;
        &.active {
          opacity: 1;
          visibility: visible;
          z-index: 1;
        }
        &:before {
          content: '';
          position: absolute;
          left: calc(-1 * (var(--gutter-24) / 2));
          width: 1px;
          height: 100%;
          background-color: var(--color-dark-2);
        }
      }

      &.active {
        overflow-y: scroll;
      }
    }
    & .page-view-selected-item-info {
      padding: var(--gutter-20);

      @media (--desktop) {
        padding: var(--gutter-16) var(--gutter-24);
        padding-left: 0;
      }
    }
    & #page-view-selected-item-edit {
      &.box {
        padding-bottom: 0;
      }
      & .wrapper {
        position: relative;
      }
      @media (--desktop) {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: var(--edit-panel-width);
        opacity: 0;
        visibility: hidden;
        z-index: 0;
        &.active {
          opacity: 1;
          visibility: visible;
          z-index: 2;
        }
      }
    }

    & .button-container {
      margin-top: var(--gutter-24);
    }
  }
  & .to-slide {
    background-color: var(--color-blue-light-2);
  }
  & .card {
    background-color: var(--color-white);
    color: var(--color-dark-blue);
    border: 1px solid var(--color-black-opacity-16);
    & .name {
      font-family: var(--fonts-family-bold);
    }
    & a {
      color: currentColor;
      &.phone {
        text-decoration: none;
      }
    }
    & .container {
      gap: var(--gutter-8);
      &.big-gap {
        gap: var(--gutter-margin-y);
      }
    }
  }
}
@media (--mobile-and-tablet) {
  #page-view-selected-item-info,
  #page-view-selected-item-edit,
  #page-view-create-item {
    position: absolute;
    top: 0;
    right: -100vw;
    width: calc(100vw - (100vw - 100%));
    max-width: 100vw;
    height: 100%;
    transition: transform 150ms linear;
    z-index: 2;
    &.active {
      transform: translate(-100vw);
    }
  }

  #page-view-selected-item-edit {
    z-index: 4;
  }
}

#app {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.page-view-wrapper,
.page-view,
#page-view-items-list,
.list-container {
  display: flex;
  min-height: 0;
}
#page-view-items-list {
  flex: 0 1 auto;
  & > div.column {
    overflow-y: hidden;
  }
}

#page-view-selected-item-edit,
#page-view-create-item {
  overflow-y: auto;
}

#page-view-selected-item-edit,
#page-view-create-item,
#page-view-selected-item-info {
  min-height: 0;
}

.row.list-container {
  position: relative;
  overflow-y: auto;
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  50% {
    display: block;
    opacity: 0.5;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes rollout {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.roll-out {
  animation: rollout 0.4s;
}
