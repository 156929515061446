/* Compass */
.compass {
  height: 70px;
  width: 70px;
  position: relative;
  background: url(@assets/svg/compass/compass.svg) no-repeat center center;
  background-size: contain;
  margin-top: var(--size-16);

  &::before {
    content: 'N';
    font-size: var(--font-size-13);
    text-align: center;
    position: absolute;
    display: block;
    height: var(--size-16);
    width: var(--size-16);
    left: 50%;
    transform: translate(-50%, -8px);
  }

  & .compass__arrow {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;

    &::before {
      content: '';
      display: block;
      background: url(@assets/svg/compass/compass-arrow.svg) no-repeat center center;
      background-size: cover;
      height: 12px;
      width: 12px;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
    }
  }
}
/* #Compass */
