.checkbox-wrapper {
  display: flex;
  align-items: center;
  &.space-between {
    justify-content: space-between;
  }

  & .input-wrapper {
    position: relative;
    width: var(--size-2);
    height: var(--size-2);
    & input {
      position: absolute;
      inset: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      z-index: 1;

      &:not(:disabled) {
        cursor: pointer;
      }

      &:checked + span svg {
        transform: scale(1);
        color: var(--color-dark-blue);
      }
      &:disabled {
        &:checked + span {
          filter: contrast(0.9);
          background-color: transparent;
          border: none;
        }
      }
      &:not(:disabled):focus-visible + span,
      &:not(:disabled):hover + span {
        box-shadow: inset 0 0 0 1px var(--color-blue-selected);
      }

      &:disabled:not(:checked) + span {
        background-color: var(--color-seledin-light-1);
      }
    }
    & span {
      display: block;
      width: 100%;
      height: 100%;
      max-height: 100%;
      background-color: var(--color-white);
      box-shadow: inset 0 0 0 1px var(--color-dark-2);
      will-change: box-shadow;
      transition: box-shadow 150ms linear;
      display: flex;
      justify-content: center;
      align-items: center;
      & svg {
        color: var(--color-dark);
        width: 75%;
        height: 75%;
        transform: scale(0);
      }
    }
  }
  & label {
    margin-bottom: 0;
    & a {
      padding-left: 0.5ch;
    }
  }
  &.reversed-direction {
    & label {
      padding-left: var(--gutter-16);
    }
  }
}
