@import url('./styles/resets.css');
@import url('./styles/normalize.css');

@import url('./assets/fonts/Gotham/fontface.css');

@import url('./styles/variables.css');
@import url('./styles/layouts.css');
@import url('./styles/inputs.css');
@import url('./styles/dropdowns.css');

@import url('./styles/base/12-grid-col-layout.css');
@import url('./styles/base/box.css');
@import url('./styles/base/grids.css');
@import url('./styles/base/columns.css');
@import url('./styles/base/rows.css');
@import url('./styles/base/icons.css');
@import url('./styles/base/table.css');
@import url('./styles/base/form.css');
@import url('./styles/base/spacers.css');
@import url('./styles/base/headers.css');
@import url('./styles/base/typography.css');
@import url('./styles/base/simple_classes.css');
@import url('./styles/base/backgrounds.css');
@import url('./styles/base/button.css');

@import url('./styles/pages.css');
@import url('./styles/react-select.css');

html,
body,
#root {
    height: 100dvh;
  width: 100%;
  font-family: var(--fonts-family-normal);
}
