.button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  padding: var(--size-1) var(--size-2);
  width: 100%;
  min-width: var(--button-min-width);
  cursor: pointer;
  font-family: var(--fonts-family-medium);
  font-size: var(--button-font-size);
  height: var(--size-48);

  &.button-simple {
    background-color: transparent;
    color: var(--color-blue-1);
    border: var(--border-transparent-2);
    padding-inline: 0;
    transition: color 0.2s ease-in;

    &:hover {
      color: var(--hover-color);
    }
  }
}

.buttons-wrapper {
  display: flex;
  gap: var(--gutter-16);

  & .button.hw {
    width: calc(50% - 8px);
  }

  & .to-the-end {
    margin-left: auto;
  }
}
