.reset-password-form-wrapper {
  flex: 1;
  background-color: var(--color-blue-light-2);
  & .buttons-wrapper {
    margin-top: auto;
  }
  @media (--desktop) {
    gap: var(--gutter-24);
  }
  @media (--mobile-and-tablet) {
    padding-left: var(--gutter-for-top-containers);
    padding-right: var(--gutter-for-top-containers);
  }
}

/* .required:after {
  content: '*';
  color: var(--color-red);
  font-size: var(--size-1);
  margin-left: var(--size-1);
} */
