.touch-point-feedback {
  z-index: 2;
  position: absolute;
  bottom: 10px;
  right: 20px;

  @media (--tablet-up) {
    z-index: 1;
  }
}

.touch-point-main-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-10);
  margin-bottom: 30px;
}
