.validated-wrapper {
  &.error {
    & label {
      position: relative;
    }
  }
  & .validated-error {
    display: block;
    line-height: var(--size-14);
    font-size: var(--size-14);
    max-height: var(--size-14);
    color: var(--color-red);
    overflow: hidden;
    &:not(:empty) {
      margin-top: var(--size-0-25);
    }
  }
}
