.sticky-bar {
  position: fixed;
  top: 0;
  margin-left: 0;
  width: 100vw;
  z-index: 3;
  transform: translateY(-100%);
  transition: transform 200ms ease-in;

  @media (--desktop) {
    display: none;
  }

  &::after {
    content: '';
    height: 5px;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.05), transparent);
    position: absolute;
    bottom: -5px;
    left: 0;
  }
}

.sticky-bar.visible {
  transform: translateY(0);
  transition: transform 200ms ease-out;
}

.sticky-content {
  background-color: var(--color-white);
  padding: var(--size-16) var(--size-20);

  & > div > p {
    flex: 1;
  }
}
