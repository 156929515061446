html {
  font-size: var(--size-16);
}
.fw {
  width: 100%;
}
.fh {
  height: 100%;
}
.fh-mobile {
  @media (--mobile-and-tablet) {
    height: 100%;
  }
}

.hw-desktop {
  @media (--mobile-and-tablet) {
    width: 100%;
  }
  width: 50%;
}
.hw {
  width: 50%;
}
.w33 {
  width: 32.4%;
}
.w66 {
  width: 65.4%;
}
.hidden {
  display: none;
}

.container {
  display: flex;
  &.container-center {
    flex-direction: column;
    align-items: center;
  }
  &.container-top {
    flex-direction: column;
    align-items: flex-start;
  }
  &.margined-bottom {
    margin-bottom: var(--gutter-margin-y);
  }
  & .padded-bottom {
    padding-bottom: var(--size-5);
  }
  & .flex-grow-1 {
    flex-grow: 1;
  }
  & .flex-grow-2 {
    flex-grow: 2;
  }
  & .flex-grow-3 {
    flex-grow: 3;
  }
}

.tab-panel {
  display: flex;
  flex-direction: column;
  gap: var(--gutter-card);
  flex-wrap: wrap;

  @media (--desktop) {
    max-width: calc((((100vw - (11 * var(--gutter-24))) / 12) * 6) + (5 * var(--gutter-24)));
  }

  @media (--desktop-small) {
    max-width: 100%;
  }
}

.cards__wrapper {
  @media (--desktop) {
    max-width: calc((((100vw - (11 * var(--gutter-24))) / 12) * 6) + (5 * var(--gutter-24)));
  }

  @media (--desktop-small) {
    max-width: 100%;
  }
}

.flex-row {
  display: flex;
  align-items: center;

  &.flex-row--space-between {
    justify-content: space-between;

    @media (--mobile) {
      display: block;
    }
  }
}

.flex-row {
  display: flex;
  align-items: center;
}

.sticky {
  align-self: start;
  position: sticky;
  top: 140px;
}
