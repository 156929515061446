.image-uploader-wrapper {
  --image-min-height: 15rem;

  max-width: 22rem;

  .image-uploader-label {
    min-height: var(--image-min-height);
    border: var(--border-dashed-2);
    display: grid;
    place-items: center;
    position: relative;
    text-decoration: underline;
    color: var(--color-blue-1);
    cursor: pointer;
    padding: 0;
  }

  #image-uploader-input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  .cropper-wrapper {
    position: relative;
    min-height: var(--image-min-height);
  }

  .logotype {
    width: 100%;
    max-width: 100%;
    max-height: max-content;
  }
}

.image-uploader-wrapper .image-uploader-label:has(#image-uploader-input:focus-visible),
.image-uploader-wrapper .image-uploader-label:hover {
  border-style: solid;
}
