@keyframes pulse {
  20% {
    scale: 0.8;
  }
  60% {
    scale: 1.4;
  }
  100% {
    scale: 1;
  }
}

.animate-map-option-button {
  animation: pulse 0.3s ease-in-out forwards 0.3s;
}
