.input-suffix {
  & .input-group {
    display: flex;
    align-items: stretch;
    position: relative;
    margin-top: var(--size-0-25);
    border: var(--border-grey-single);
    transition: border 0.2s ease-in;

    &:focus-within {
      border: 1px solid var(--color-blue-selected);
    }

    &:hover {
      border: 1px solid var(--color-blue-selected);
    }

    & input {
      border: none;
      margin-top: 0;
    }

    & input:focus-visible {
      outline: none;
    }

    & .suffix {
      padding-inline: var(--size-1);
      background-color: white;
      height: var(--size-48);

      & > span {
        display: flex;
        align-items: center;
        height: 100%;
      }
      &.disabled {
        background-color: var(--color-seledin-light-1);
      }
    }
  }

    & label {
        line-height: 24px;
    }
}
