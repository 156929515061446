.grid-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: var(--gutter-16);
  grid-row-gap: var(--gutter-16);
  grid-auto-flow: dense;

  @media (--desktop) {
    padding: 0;
    grid-column-gap: var(--gutter-24);
    grid-row-gap: var(--gutter-24);
  }

  &.w980-layout {
    width: 100%;
    max-width: var(--grid-with-margins-max-width);
    margin: auto;
  }
}

@for $i from 1 to 12 {
  .col-$i {
    grid-column-end: span $(i);
  }
}
@for $i from 2 to 12 {
  .col-offset-$i {
    grid-column-start: $(i);
  }
}

@media (--mobile-small) {
  @for $i from 1 to 12 {
    .col-mobile-small-$i {
      grid-column-end: span $(i);
    }
  }
  @for $i from 2 to 12 {
    .col-mobile-small-offset-$i {
      grid-column-start: $(i);
    }
  }
}

@media (--mobile) {
  @for $i from 1 to 12 {
    .col-mobile-$i {
      grid-column-end: span $(i);
    }
  }
  @for $i from 2 to 12 {
    .col-mobile-offset-$i {
      grid-column-start: $(i);
    }
  }
}

@media (--tablet) {
  @for $i from 1 to 12 {
    .col-tablet-$i {
      grid-column-end: span $(i);
    }
  }
  @for $i from 2 to 12 {
    .col-tablet-offset-$i {
      grid-column-start: $(i);
    }
  }
}

@media (--desktop) {
  @for $i from 1 to 12 {
    .col-desktop-$i {
      grid-column-end: span $(i);
    }
  }
  @for $i from 2 to 12 {
    .col-desktop-offset-$i {
      grid-column-start: $(i);
    }
  }
}

@media (--desktop-small) {
  @for $i from 1 to 12 {
    .col-desktop-small-$i {
      grid-column-end: span $(i);
    }
  }

  @for $i from 2 to 12 {
    .col-desktop-small-offset-$i {
      grid-column-start: $(i);
    }
  }
}

@media print {
  @for $i from 1 to 12 {
    .col-print-$i {
      grid-column-end: span $(i);
    }
  }

  @for $i from 2 to 12 {
    .col-print-offset-$i {
      grid-column-start: $(i);
    }
  }
}
