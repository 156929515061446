form {
  & .required {
    position: relative;

      & label:first-of-type::after {
      content: '*';
      color: var(--color-red);
      font-size: var(--font-size-4);
      display: inline-block;
      padding-left: var(--size-0-25);
    }
  }
}
