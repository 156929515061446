.horizontal-line {
  border: 0;
  border-bottom: 1px solid var(--color-brown-3);
  width: 100%;
  padding: 0;

    &.gray {
        border-bottom: var(--border-card-frame);
    }

    &.dark {
    border-bottom: var(--border-grey-dark);
  }
}

.form-wrapper {
  & .horizontal-line {
    margin-top: var(--gutter-16);
    margin-bottom: var(--gutter-16);
  }
}
