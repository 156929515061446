.chips-wrapper {
  gap: var(--size-0-25);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.chips-container {
  display: flex;
  & .chips {
    position: relative;
    flex-shrink: 0;
    & label {
      user-select: none;

      display: flex;
      align-items: center;

      margin: 0;
      background: var(--color-blue-light-3);
        transition: background-color 0.1s linear,
        color 0.1s linear;
    }

    & input[type='radio'] {
      position: absolute;
      opacity: 0;
      &:disabled + label {
        background-color: var(--disabled-bg);
        color: var(--disabled-text);
        &:checked + label {
          background-color: var(--color-blue-light-2);
          color: var(--color-dark-2);
        }
      }

      &:not(:disabled) {
        &:checked + label,
        &:focus-visible + label,
        &:hover + label {
          background-color: var(--color-blue-selected);
          border-color: var(--color-blue-selected);
          color: white;
          cursor: pointer;
        }
        &:focus-visible + label {
          outline: 2px solid var(--color-blue-selected);
          outline-offset: 1px;
        }
      }
    }

    &.chips--small label {
      font-size: var(--font-size-13);
      border-radius: var(--size-28);
      height: var(--size-28);
      padding: 0 var(--size-0-5);
    }

    &.chips--large label {
      font-size: var(--button-font-size);
      border-radius: var(--size-42);
      height: var(--size-40);
      padding: 0 var(--size-16);
      font-family: var(--fonts-family-medium);
    }

    &.chips--square label {
      font-size: var(--font-size-13);
      background: var(--color-white);
      border: 1px solid var(--color-dark-blue-grey-2);
      border-radius: var(--size-0-25);
      height: var(--size-32);
      padding: 0 var(--size-0-5);
      line-height: 1;

      &:not([class*='disabled']) {
        &:hover {
          background-color: var(--color-dark-blue);
          border-color: var(--color-dark-blue);
          color: var(--color-white);
        }
      }
    }
  }
}
