.bg {
  padding: 0 var(--gutter-20);

  @media (--desktop) {
    padding: 0;
  }

  &.bg-brown-1 {
    background-color: var(--color-brown-1);
    padding: var(--gutter-36) var(--gutter-20);

    @media (--desktop) {
      padding: var(--size-60) 0;
    }
  }
  &.bg-brown-2 {
    background-color: var(--color-brown-2);
    padding: var(--gutter-36) var(--gutter-20);

    @media (--desktop) {
      padding: var(--size-60) 0;
    }
  }
  &.bg-brown-3 {
    background-color: var(--color-brown-3);
    padding: var(--gutter-36) var(--gutter-20);

    @media (--desktop) {
        padding: var(--size-60) 0 var(--size-80) 0;
    }
  }

  &.bg-gray {
    background-color: var(--color-blue-light-2);
    padding: var(--gutter-36) var(--gutter-20);

      @media (--desktop) {
          padding: var(--size-60) 0;
      }
  }

    &.bg-beige {
        background-color: var(--color-beige);
        padding: var(--gutter-36) var(--gutter-20);

    @media (--desktop) {
        padding: var(--size-60) 0 var(--size-80) 0;
    }
  }

  &.bg-light-blue-2 {
    background-color: var(--color-blue-light-2);
    padding: var(--gutter-24);
    border: 1px solid var(--color-black-opacity-16);
  }

  &.bg-no-border {
    border: none;
  }
  &.no-padding {
    padding: 0;
  }
}
