:root {
  --for-milky-filter: #e9e7e754;

  --color-blue-1: #0072ce;
  --color-blue-light-1: #edf0f2;
  --color-blue-light-2: #f6f7f9;
  --color-blue-light-3: #dce8f4;
  --color-dark: #000000;
  --color-dark-2: #cccccc;
  --color-seledin: #00a3ad;
  --color-seledin-light-1: #f2f3f4;
  --color-green-1: #00bf6f;
  --color-yellow: #ffcd00;
  --color-red: #c8102e;
  --color-brown-1: #7b6469;
  --color-brown-2: #c5b9ac;
  --color-brown-3: #ede9e5;
  --color-dark-blue: #0f1923;
    --color-dark-blue-grey-1: #757575;
  --color-dark-blue-grey-2: #b6b9bc;
  --color-blue-selected: #002543;
  --color-blue-4: #dde9f5;
  --color-blue-5: #0a287d;
  --color-white: #ffffff;
  --color-black-opacity-16: #00000029;
  --color-beige: #f5f5f5;
  --color-brown-1-roof-gradient: #c6baac;
  --color-brown-2-roof-gradient: #bba998;
  --color-black-opacity-44: rgba(0, 0, 0, 0.044);
    --color-light-grey: #dee4eb;

  --hover-color: var(--color-blue-selected);
  --disabled-bg: #f1f2f3;
  --disabled-text: var(--color-dark-blue-grey-1);

  --font-dark: var(--color-dark);
  --font-light: var(--color-dark-blue-grey-1);
  --fonts-family-normal: 'Gotham Book', Arial, Helvetica, sans-serif;
  --fonts-family-bold: 'Gotham Bold', Arial, Helvetica, sans-serif;
  --fonts-family-medium: 'Gotham Medium', Arial, Helvetica, sans-serif;

  --size-0-3-em: 0.3em;
  --size-0-25: 4px;
  --size-0-5: 8px;
  --size-0-625: 10px;
  --size-0-75: 12px;
  --size-1: 16px;
  --size-1-6: 25.6px;
  --size-2: 30px;
  --size-3: 43px;
  --size-5: 64px;
  --size-6: 90px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-13: 13px;
  --size-15: 15px;
  --size-14: 14px;
  --size-16: 16px;
  --size-17: 17px;
  --size-18: 18px;
  --size-20: 20px;
  --size-22: 22px;
  --size-24: 24px;
  --size-28: 28px;
  --size-26: 26px;
  --size-30: 30px;
  --size-32: 32px;
  --size-36: 36px;
  --size-38: 38px;
  --size-40: 40px;
  --size-42: 42px;
  --size-48: 48px;
  --size-57: 57px;
  --size-60: 60px;
  --size-66: 66px;
  --size-80: 80px;
  --size-81: 81px;
  --size-96: 96px;
  --size-100: 100px;
  --size-128: 128px;
  --size-167: 167px;

  --grid-with-margins-max-width: 980px;

  --border-transparent-2: 2px solid transparent;
  --border-blue-2: 2px solid var(--color-blue-1);
  --border-grey-single: 1px solid var(--color-dark-2);
  --border-grey-dark: 1px solid var(--color-dark-blue-grey-2);
    --border-card-frame: 1px solid var(--color-light-grey);
  --border-beige: 1px solid var(--color-beige);
  --border-dashed-2: 1px dashed var(--color-blue-1);
  --button-font-size: var(--size-16);

  --button-min-width: 9.8rem;

  @media (--mobile-and-tablet) {
    --gutter-for-top-containers: var(--size-16);
  }
  @media (--desktop-1246) {
    --gutter-for-top-containers: 37px;
  }
  @media (--desktop-fhd) {
    --gutter-for-top-containers: 58px;
  }
  @media (--desktop-4k-up) {
    --gutter-for-top-containers: 160px;
  }
  --gutter-8: var(--size-8);
  --gutter-16: var(--size-16);
  --gutter-20: var(--size-20);
  --gutter-24: var(--size-24);
  --gutter-36: var(--size-36);
  --gutter-margin-x: var(--size-1);
  --gutter-margin-y: var(--font-size-5);
  --gutter-card: var(--size-0-53);

  --font-size-0-7: 11.68px;
  --font-size-1: 13.856px;
  --font-size-2: var(--size-1);
  --font-size-3: var(--size-17);
  --font-size-4: 21.328px;
  --font-size-5: 25.6px;
  --font-size-10: var(--size-10);
  --font-size-13: var(--size-13);
  --font-size-17: var(--size-17);
  --font-size-20: var(--size-20);
  --font-size-24: var(--size-24);

  --lead-max-width: 24.374rem;

  --icon-small: 12px;
  --icon-small-2: 16px;
  --icon-medium: 24px;
  --icon-large: 40px;

  --input-padding: 3px;
  --input-size-y: 3.2rem;
  --input-size-x-1: 4.66rem;
  --input-size-x-2: 9.86rem;
  --input-size-x-3: 11.07rem;

  --control-border-radius: 5px;

  --edit-panel-width: 390px;
}

@custom-media --mobile-small (width <= 20rem);
@custom-media --mobile (width > 20rem) and (width <= 48rem);
@custom-media --mobile-and-small (width <= 48rem);
@custom-media --mobile-small-up (width > 20rem);
@custom-media --mobile-up (width > 48rem);
@custom-media --mobile-and-tablet (width < 64rem);
@custom-media --tablet (width >= 48rem) and (width < 64rem);
@custom-media --tablet-up (width > 48rem);
@custom-media --desktop-small (width >= 64rem) and (width < 75rem);
@custom-media --desktop (width >= 64rem);
@custom-media --desktop-1246 (width > 77.875rem) and (width < 120rem);
@custom-media --desktop-fhd (width >= 120rem) and (width < 240rem);
@custom-media --desktop-4k-up (width >= 240rem);
