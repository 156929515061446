.price-row {
    min-height: 38px;
    max-height: 38px;
  align-items: center;

  &.price-row--number {
    @media (--mobile) {
      display: block;

      & > p {
        padding-bottom: var(--gutter-8);
      }

      & > .row {
        width: 100%;
      }

      & .numeric-container {
        width: auto;

        & label {
          display: none;
        }
      }
    }
  }
}
